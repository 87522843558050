import React, { ReactElement, ReactNode, ReactNodeArray } from "react";
import Img, { FluidObject } from "gatsby-image";

type ActivityBoxProps = {
  readonly qualificationNeeded: boolean;
  readonly title: string | ReactElement;
  readonly image: FluidObject;
  readonly children: ReactNode | ReactNodeArray;
};

export default function ActivityBox({
  title,
  qualificationNeeded,
  image,
  children,
}: ActivityBoxProps): ReactElement {
  return (
    <div className="col-xs-12 col-sm-4">
      <div className="gallery-box with-hover">
        <Img fluid={image} alt={title.toString()} />
        <span className="text">
          {title} {!qualificationNeeded && <span className="asterisk">*</span>}
        </span>
        <div className="gallery-box-hover">{children}</div>
      </div>
    </div>
  );
}
